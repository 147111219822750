
import {
  AnalyticFilter,
  AnalyticsState,
  AnalyticsTypes,
} from "@/store/modules/analytics/analytics.types";
import VueApexCharts from "vue-apexcharts";
import { Vue, Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";

const analyticX = namespace("Analytics");

@Component({
  components: {
    StatsAvatar: () => import("@/components/dashboard/StatsAvatar.vue"),
    VueApexCharts,
  },
})
export default class TicketsByProduct extends Vue {
  @analyticX.State(AnalyticsTypes.PRODUCT_ANALYTICS)
  public productAnalytics!: AnalyticsState;

  @analyticX.Action(AnalyticsTypes.LOAD_PRODUCT_ANALYTICS)
  public loadProductStats!: (filter: AnalyticFilter) => void;

  get series(): unknown {
    return [
      {
        name: "Tickets",
        data: this.productAnalytics.analytics.map((a) => ({
          x: a.name,
          y: a.count,
        })),
      },
    ];
  }

  public options: unknown = {
    plotOptions: {
      bar: {
        borderRadius: 8,
        columnWidth: "30%",
        borderRadiusApplication: "end",
      },
    },
    fill: {
      colors: [
        "#EE5373",
        "#6E838E",
        "#429EF7",
        "#6EE6A6",
        "#F7BB4E",
        "#8B73D7",
        "#F07A44",
        "#51A89C",
      ],
    },
    grid: {
      show: true,
      borderColor: "#EEEEEE",
      column: {
        opacity: 1,
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
      xaxis: {
        lines: {
          show: true,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: true,
      },
      title: {
        text: "Products",
        offsetY: 90,
      },
    },
    yaxis: {
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: true,
      },
      labels: {
        style: {
          colors: ["#757575"],
        },
        formatter: (value: number) => value.toFixed(0),
      },
      min: 0,
      title: {
        text: "Tickets",
      },
    },
    chart: {
      toolbar: {
        show: false,
      },
    },
  };

  @Prop({ default: undefined }) public start?: string;
  @Prop({ default: undefined }) public end?: string;

  filterChanged(value: { forced?: boolean }): void {
    this.loadProductStats({
      start: this.start,
      end: this.end,
      force: value.forced,
    });
  }

  mounted(): void {
    this.filterChanged({});
  }
}
